import actions from './actions'

const initialState = {
  currentUser: {},
  currentStep: 0,
  selectedTimeSlot: '',
  selectedTimeSlotString: '',
  customMessage: '',
  showMeetingPopover: false,
  isMeetingWindowVisible: false,
  allMeetings: {
    confirmed: {},
    sent: {},
    received: {},
  },
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
