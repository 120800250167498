import actions from './actions'

const initialState = {
  mainCategory: '',
  mainCategoryString: '',
  primaryCategory: '',
  secondaryCategory: '',
  primaryFilter: [],
  secondaryFilter: '',
  countryFilter: '',
  classificationFilter: '',
  regionFilter: '',
  directoryloading: false,
  directoryData: {},
  currentPage: 0,
  currentRecords: 50,
  showLoadMore: false
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
