const actions = {
  GET_INIT: 'virtualSetup/GET_INIT',
  SET_STATE: 'virtualSetup/SET_STATE',
  GET_DATA: 'virtualSetup/GET_DATA',
  SAVE_DATA: 'virtualSetup/SAVE_DATA',
  SAVE_AGENDA: 'virtualSetup/SAVE_AGENDA',
  SAVE_SPEAKERS: 'virtualSetup/SAVE_SPEAKERS',
  SET_AGENDA: 'virtualSetup/SET_AGENDA',
  SET_DEFAULT: 'virtualSetup/SET_DEFAULT',
  GET_DEFAULT: 'virtualSetup/GET_DEFAULT',
  ADD_SCENE: 'virtualSetup/ADD_SCENE',
  SET_SCENE: 'virtualSetup/SET_SCENE',
  UPLOAD_SCENE_BG: 'virtualSetup/UPLOAD_SCENE_BG',
  UPLOAD_FILES: 'virtualSetup/UPLOAD_FILES',
  GET_AGENDA: 'virtualSetup/GET_AGENDA',
  GET_SPEAKERS: 'virtualSetup/GET_SPEAKERS',
  UPLOAD_SCENE_LOGO: 'virtualSetup/UPLOAD_SCENE_LOGO',
  GET_EXHIBITORS: 'virtualSetup/GET_EXHIBITORS',
  GET_MODERATORS: 'virtualSetup/GET_MODERATORS',
  SEND_NOTIFICATIONS: 'virtualSetup/SEND_NOTIFICATIONS',
  SAVE_MENU: 'virtualSetup/SAVE_MENU',
  GET_MENU: 'virtualSetup/GET_MENU',
  DELETE_CLICKSECTION: 'virtualSetup/DELETE_CLICKSECTION',
  DELETE_SESSION: 'virtualSetup/DELETE_SESSION',
  SEND_FOLLOW_ME: 'virtualSetup/SEND_FOLLOW_ME',
  GET_SESSION_MESSAGES:'virtualSetup/GET_SESSION_MESSAGES',
}

export default actions
