const actions = {
  SET_STATE: 'streamfeature/SET_STATE',
  SET_MESSAGE: 'streamfeature/SET_MESSAGE',
  SEND_MESSAGE: 'streamfeature/SEND_MESSAGE',
  SET_QUESTION: 'streamfeature/SET_QUESTION',
  SET_POLLING: 'streamfeature/SET_POLLING',
  FETCH_MESSAGES: 'streamfeature/FETCH_MESSAGES',
  FETCH_QUESTIONS: 'streamfeature/FETCH_QUESTIONS',
  SEND_QUESTION: 'streamfeature/SEND_QUESTION',
  SEND_CREATE_POLL: 'streamfeature/SEND_CREATE_POLL',
  ALLOW_QANDA: 'streamfeature/ALLOW_QANDA',
  ALLOW_POLL: 'streamfeature/ALLOW_POLL',
  ANSWER_POLL: 'streamfeature/ANSWER_POLL',
  FETCH_POLL: 'streamfeature/FETCH_POLL',
  EMAIL_NOTE: 'streamfeature/EMAIL_NOTE'
}

export default actions
