import React from 'react'
import { Row, Col, Card, Avatar, Badge } from 'antd'

import './leaderBoardSingleCard.less'

/* eslint-disable react/jsx-indent, camelcase */

class LeaderBoardSingleCard extends React.Component {
  render() {
    const {
      rankersDetails: { points, name, my_rank },
    } = this.props
    return (
      <div>
        <Row gutter={0} id="modalContent" className="modalContent">
          <Col span={24}>
            <Card className="top_ranker_card">
              <Row>
                <Col xs={24} sm={24} md={24}>
                  <span className="user_avatar">
                    <Badge count={`0${my_rank}`}>
                      <Avatar size={64}>{name.charAt(0).toUpperCase()}</Avatar>
                    </Badge>
                  </span>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <span className="user_desc">
                    <h3>{name}</h3>
                    {/* <p>Wizzy</p> */}
                  </span>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <span className="user_points">
                    <div className="pointsContainer">{`${points} Points`}</div>
                  </span>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default LeaderBoardSingleCard
