import API from '../config/api'
  
  export async function getSavedData() {
    return API.get('getAllStallDesignScenes')
  }

  export async function writeData(data) {
    console.log('Save the Stall')
    console.log(data)
    return API.post('saveStallDesignScene', data.load, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  export async function getRoles() {
    return API.get('getAllRoles')
  }

  export async function deleteStallSection(data) {
    return API.get(`deleteStallClickSection/${data}`)
  }