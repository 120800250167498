import { all, call, takeEvery, put,select } from 'redux-saga/effects'
import { getAllTasks } from 'services/virtual/pointsredeem'
import { message } from 'antd'
import actions from './actions'

export function* GET_TASKS() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    const taskData = yield call(getAllTasks, {token: accessToken})

    if (taskData.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          totalPoints: taskData.data.totalPoints,
          myPoints: taskData.data.userScore,
          tasks: taskData.data
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TASKS, GET_TASKS),
  ])
}
