import React from 'react'
import { connect } from 'react-redux'
import { 
  Modal, 
  Row, 
  Col, 
  // Button 
} from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import { 
  // MailOutlined, 
  CloseCircleOutlined } from '@ant-design/icons'
import CsvDownload from 'react-json-to-csv'
// import actions from 'redux/virtual/event/actions'
import LeadsList from './LayoutComponents/LeadsList'

// import './download.less'

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
@connect(({ virtualSettings, virtualEvent }) => ({ virtualSettings, virtualEvent }))
class Leads extends React.Component {
  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtualSettings/CHANGE_SETTING',
      payload: {
        setting: 'isLeadsOpen',
        value: false,
      },
    })
  }

  // downloadCSV = () => {
  //   const { dispatch } = this.props
  //   CsvDownload
  // }

  render() {
    const {
      virtualSettings: { isLeadsOpen },
      virtualEvent: { leads },
    } = this.props

    const normalizedLeads = []
    Object.entries(leads).map((key) => {
      normalizedLeads.push({
        name: key[1].message.name,
        email: key[1].message.email,
        message: key[1].message.customMessage
      })
      return ''
    })
    return (
      <>
        <Modal
          visible={isLeadsOpen}
          style={{
            top: '0px',
            maxHeight: 'calc(100vh - 180px)',
          }}
          wrapClassName="downloadModal"
          width="575px"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleOutlined style={{ color: '#ffe600', fontSize: '25px' }} />}
          destroyOnClose
        >
          <Row id="modalHeader" className="modalHeader">
            <Col span={24} className="text-center">
              <h3>The Leads</h3>
            </Col>
          </Row>
          <Row gutter={24} id="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 185px)">
              <Col span={24}>
                {/* <Button
                  type="primary"
                  shape="round"
                  icon={<MailOutlined />}
                  onClick={this.downloadCSV}
                  size="medium"
                  style={{ float: 'right' }}
                  loading={leadDownloadLoading}
                >
                  Download Leads
                </Button> */}
                <CsvDownload 
                  data={normalizedLeads}
                  filename="leads.csv"
                  style={{
                    background: "#ffe600",
                    borderColor: "#ffe600",
                    // display:"inline-block",
                    cursor:"pointer",
                    fontSize:"15px",
                    fontWeight:"bold",
                    padding:"6px 24px",
                    // textDecoration:"none",
                    float: "right",
                    margin: "5px 5px",
                    color: '#181818'
                    }}
                >
                  Download Leads
                </CsvDownload>
                <br />
                <br />
                <br />
                {<LeadsList />}
              </Col>
            </Scrollbars>
          </Row>
        </Modal>
      </>
    )
  }
}

export default Leads
