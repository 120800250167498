import API from '../config/api'

export async function sendMeetingRequest(data) {
  return API.post('meetingRequest', data, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function getMeetingRequest(data) {
  return API.get('getMeetings', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function acceptRequest(data) {
  return API.post(
    'acceptRequest',
    { id: data.id, meetingID: data.meetingID },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    },
  )
}

export async function rejectRequest(data) {
  return API.post(
    'rejectRequest',
    { id: data.id },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    },
  )
}
