import API from '../config/api'

export async function getTotalRegistrations() {
  return API.get('getTotalRegistrations')
}

export async function getSceneData() {
  return API.get('getPlatformTotalTimeUsuage')
}

export async function getCurrentData() {
  return API.get('getSceneActiveCount')
}
