import actions from './actions'

const initialState = {
  stalls: {},
  currentStall: '',
  roles: []
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_STALL:
      return {
        ...state,
        stalls: {
          ...state.stalls,
          [action.payload.currentStall]: {
            ...state.stalls[action.payload.currentStall],
            [action.payload.key]: action.payload.actualload,
          },
        },
      }
    default:
      return state
  }
}
