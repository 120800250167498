import actions from './actions'

const initialState = {
  emojis: {
    "red-heart": "resources/images/emojis/red-heart.png",
    "fire": "resources/images/emojis/fire.png",
    // "smiling-face-with-heart-eyes": "resources/images/emojis/smiling-face-with-heart-eyes.png",
    "clapping-hands": "resources/images/emojis/clapping-hands.png",
    "partying-face": "resources/images/emojis/partying-face.png",
    "thumbs-up": "resources/images/emojis/thumbs-up.png",
  },
  lastEmoji: '',
  recentEmojis: {},
  totalEmojiFlying: 0
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
