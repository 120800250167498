import { call, all, takeEvery, put, select } from 'redux-saga/effects'
import {
  sendMeetingRequest,
  getMeetingRequest,
  acceptRequest,
  rejectRequest,
} from 'services/virtual/meetings'
import { message } from 'antd'
import actions from './actions'

export function* SEND_MEETING() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
      meetings: { currentUser, selectedTimeSlotString, customMessage, allMeetings },
    } = state
    const finalJSON = {
      user: currentUser.id,
      timeSlot: selectedTimeSlotString,
      message: customMessage,
      token: accessToken,
      currentStatus: 'sentforapproval',
    }

    yield call(sendMeetingRequest, finalJSON)
    const tempAllMeetings = allMeetings
    tempAllMeetings.sent[currentUser.id] = {
      user: currentUser,
      timeSlot: selectedTimeSlotString,
      message: customMessage,
      currentStatus: 'sentforapproval',
    }
    yield put({
      type: actions.SET_STATE,
      payload: {
        currentUser: {},
        currentStep: 0,
        selectedTimeSlot: '',
        selectedTimeSlotString: '',
        customMessage: '',
        showMeetingPopover: false,
        isMeetingWindowVisible: false,
        allMeetings: tempAllMeetings,
      },
    })
    message.success('Meeting Request Sent Successfully')
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* GET_MEETING() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    const result = yield call(getMeetingRequest, { token: accessToken })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          allMeetings: result.data,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* ACCEPT_REQUEST(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
      meetings: { allMeetings },
    } = state

    const result = yield call(acceptRequest, {
      token: accessToken,
      id: data.payload.id,
      meetingID: data.payload.meetingID,
    })
    if (result.status === 200) {
      const tempAllMeetings = allMeetings
      tempAllMeetings.confirmed[data.payload.id] =
        allMeetings[data.payload.meetingType][data.payload.id]
      tempAllMeetings.confirmed[data.payload.id].meetingID = data.payload.meetingID
      tempAllMeetings.confirmed[data.payload.id].currentStatus = 'approved'
      delete tempAllMeetings[data.payload.meetingType][data.payload.id]
      yield put({
        type: actions.SET_STATE,
        payload: {
          allMeetings: tempAllMeetings,
        },
      })
      message.success('Approved')
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* REJECT_REQUEST(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state

    const result = yield call(rejectRequest, { token: accessToken, id: data.payload.id })
    if (result.status === 200) {
      // yield put({
      //   type: actions.SET_STATE,
      //   payload: {
      //     allMeetings: result.data
      //   }
      // })
      message.success('Rejrct')
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SEND_MEETING, SEND_MEETING),
    takeEvery(actions.GET_MEETING, GET_MEETING),
    takeEvery(actions.ACCEPT_REQUEST, ACCEPT_REQUEST),
    takeEvery(actions.REJECT_REQUEST, REJECT_REQUEST),
  ])
}
