import React from 'react'
import { connect } from 'react-redux'
import VisitingCardLayout from 'pages/virtual/event/main/LayoutComponents/VisitingCard/VisitingCardLayout'
// import actions from 'redux/virtual/event/actions'

@connect(({ virtualEvent, virtualSettings }) => ({ virtualEvent, virtualSettings }))
class LeadsList extends React.Component {
  getLeadItem = item => {
    const {
      virtualSettings: { isVirtualMobileView },
    } = this.props
    const hideNumber = 'notRequired'
    return (
      <div className="downloadItem">
        <VisitingCardLayout
          hideNumber={hideNumber}
          name={item[1].message.name}
          number={item[1].message.number}
          email={item[1].message.email}
          organization={item[1].message.organization}
          designation={item[1].message.designation}
          isVirtualMobileView={isVirtualMobileView}
          theDP={item[1].message.avatar}
          customMessageView="leads"
          thereceivedCustomMessage={item[1].message.customMessage}
          thefinalWidth="550px"
        />
      </div>
    )
  }

  render() {
    const {
      virtualEvent: { leads },
    } = this.props

    const showcase = Object.entries(leads).map(key => {
      return this.getLeadItem(key)
    })

    return <>{showcase}</>
  }
}

export default LeadsList
