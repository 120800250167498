import { 
  all, 
  call, 
  takeEvery, 
  select,
  put 
} from 'redux-saga/effects'
// import { getAgenda, getAllSpeakers } from 'services/virtual/setup'
import { pubnubPublish,pubnubFetchMessages } from 'services/virtual/chat'
import { message } from 'antd'
import uuid from 'react-uuid'
import actions from './actions'


export function* SEND_FEEDBACK(data) {
  try {
    const state = yield select()
    const { 
      Chat: { pubnub },
      virtualUser: { id, name, avatarType, avatarData, avatarColor },
      // virtualEvent: {homescreen},
    } = state

    const theAvatar = avatarType === 'text'
    ? `https://via.placeholder.com/50x50.png/${avatarColor.substring(
        1,
      )}/fff?text=${avatarData}`
    : avatarData
    const finalMessage = {
      message_id: uuid(),
      thefeedback: data.payload.message,
      publisher: id,
      type: 'messagebox',
      title: name,
      avatar: theAvatar
        
    }

    const publishData = {
      channelID: process.env.REACT_APP_MESSAGEBOX_CHANNEL,
      message: finalMessage,
      pubnub,
    }
    const result = yield call(pubnubPublish, publishData)

    const utcSeconds = Math.round(result.timetoken / 10000000)
    const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(utcSeconds)

    const toAdd = {
      position: 'right',
      type: 'text',
      text: data.payload.message,
      date: d,
      status: 'recieved',
      title: name,
      avatar: theAvatar,
      notch: false,
      replyButton: false,
    }

    yield put({
      type: actions.SET_MESSAGE,
      payload: {
        toAdd,
      },
    })

  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* FETCH_FEEDBACK() {
  try {
    const state = yield select()
    const {
      virtualUser: { id },
      Chat: {pubnub}
    } = state
    const fetchResult = yield call(pubnubFetchMessages, {pubnub,channels: [process.env.REACT_APP_MESSAGEBOX_CHANNEL]})
    const messageboxmessages = []
    Object.entries(fetchResult.channels).map(key => {
      key[1].forEach(obj => {
        const utcSeconds = Math.round(obj.timetoken / 10000000)
        const d = new Date(0) // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds)
        const finalJSON = {
          message_id: obj.message.message_id,
          position: obj.message.publisher === id ? 'right' : 'left',
          type: 'text',
          originalPublisher: obj.message.publisher,
          text: obj.message.thefeedback,
          date: d,
          title: obj.message.title,
          notch: false,
          avatar: obj.message.avatar,
          replyButton: false,
        }
        messageboxmessages.push(finalJSON)
      })
      return ''
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        messageBoxMessages: messageboxmessages
      },
    })
  } catch (err) {
    console.log(err)
    console.log(err.status)
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.SEND_FEEDBACK, SEND_FEEDBACK),
    takeEvery(actions.FETCH_FEEDBACK, FETCH_FEEDBACK)
  ])
}
