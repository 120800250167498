import API from '../config/api'

export async function getGifts(data) {
  return API.get('fetchGifts', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function redeemGifts(data) {
  return API.get(`redeemGift/${data.giftid}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}
