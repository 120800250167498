import API from '../config/api'

export async function fetchEnteries(data) {
  return API.get(`getContestEnteries?page=0&size=25&widgetid=${data.widgetid}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function entryUpload(data) {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("widgetId", data.currentContestID);
    console.log(data)
    return API.post('uploadContestEntry', formData, data.config, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          'Content-Type': 'multipart/form-data',
        }
      })
  }

export async function logout() {
    return ""
}

export async function removeLikeEntry(data) {
  return API.get(`removeLikeEntry/${data.entryID}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}

export async function likeEntry(data) {
  return API.get(`likeEntry/${data.entryID}`, {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  })
}