import { all, call, takeEvery, put, select } from 'redux-saga/effects'
import { initiateOrder, storeCreds,verifyHash,updateTransaction } from 'services/virtual/payment'
import { message } from 'antd'
import registeractions from 'redux/virtual/registerUser/actions'
import actions from './actions'

const md5 = require('md5')

export function* INIT_PAYMENT(data) {
  try {
    const result = yield call(initiateOrder,{finalToken:data.payload.finalToken})
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          hashLoading: false,
          hashToken: result.data
        },
      })
    }
  } catch (err) {
    console.log(err)
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* VERIFY_HASH(data) {
  try {
    const state = yield select()
    const {
      registerVirtualUser: { current,userID },
    } = state
    const result = yield call(verifyHash,{transactionResponse:data.payload.transactionResponse,user_id: userID,amount: data.payload.amount})
    console.log("response is here")
    console.log(result)
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          postpaymentloading: false,
        },
      })
      if(result.data)
      {
        yield put({
          type: registeractions.SET_STATE,
          payload: {
            current: current + 1,
          },
        })
      }
      else{
        yield put({
          type: actions.SET_STATE,
          payload: {
            transactionResult: true,
          },
        })
      }
    }
  } catch (err) {
    console.log(err)
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* UPDATE_TRANSACTION(data) {
  try {
    const state = yield select()
    const {
      registerVirtualUser: { userID },
    } = state
    const result = yield call(updateTransaction,{transactionResponse:data.payload.transactionResponse,user_id: userID})
    console.log("response is here")
    console.log(result)
  } catch (err) {
    console.log(err)
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* STORE_CREDS(data) {
  try {
    const state = yield select()
    const {
      registerVirtualUser: { current, userID },
    } = state
    yield put({
      type: actions.SET_STATE,
      payload: {
        paymentID: data.payload.paymentID,
        receivedPaymentID: data.payload.orderID,
        signature: data.payload.signature,
      },
    })
    const result = yield call(storeCreds, {
      razorpay_payment_id: data.payload.paymentID,
      razorpay_order_id: data.payload.orderID,
      razorpay_signature: data.payload.signature,
      user_id: userID,
    })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          postpaymentloading: false,
        },
      })
      if (result.data.response === md5(`${data.payload.orderID}success${data.payload.signature}`)) {
        yield put({
          type: registeractions.SET_STATE,
          payload: {
            current: current + 1,
          },
        })
      } else {
        message.error('Transaction failed')
      }
    }
  } catch (err) {
    console.log(err)
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.INIT_PAYMENT, INIT_PAYMENT),
    takeEvery(actions.STORE_CREDS, STORE_CREDS),
    takeEvery(actions.VERIFY_HASH, VERIFY_HASH),
    takeEvery(actions.UPDATE_TRANSACTION, UPDATE_TRANSACTION),
  ])
}
