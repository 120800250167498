import React from 'react'
import { Row, Col, Button } from 'antd'
import { DownloadOutlined,LeftOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import actions from 'redux/virtual/aiphotobooth/actions'
import Base64Downloader from 'react-base64-downloader'

const mapStateToProps = ({ aiphotobooth }) => ({ aiphotobooth })

@connect(mapStateToProps)
class Download extends React.Component {
  goBack = () => {
    const {dispatch, aiphotobooth: {currentStep}} = this.props
    dispatch({
      type: actions.SET_STATE,
      payload:{
        currentStep: currentStep - 1,
      }
    })
  }

  resetPhotobooth = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: 0,
        imageSrc: '',
        webcamLoading: false,
      },
    })
  }
  // downloadFile = () => {
  //   const {photobooth: {imageSrc}} = this.props

  // }

  render() {
    const {
      aiphotobooth: { imageSrc },
    } = this.props
    return (
      <>
        <Row justify="space-around" align="middle" className="text-center">
          <Col span={24} className="text-center">
            <img src={imageSrc} alt="newImage" style={{ width: '100%' }} />
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px', padding: '15px' }}>
          <Col span={24}>
            <h3>Download &amp; Share</h3>
            <h6>LOOKING GREAT! <br /></h6>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={24} className="text-center downloadButton">
            <Base64Downloader base64={imageSrc} downloadName="wizPhotobooth">
              <Button shape="circle" size="large" className="actualDownloadButton">
                <DownloadOutlined />
              </Button>
            </Base64Downloader>
          </Col>
        </Row>
        <Row justify="space-around" align="middle" style={{ marginTop: '15px' }}>
          <Col span={8} className="text-center">
            <Button type="text" onClick={this.goBack} size="medium">
              <LeftOutlined /> &nbsp; Back
            </Button>
          </Col>
          <Col span={8} className="text-center" />
          <Col span={8} className="text-center">
            <Button type="text" onClick={this.resetPhotobooth} size="medium">
              Once More!
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}

export default Download
