import React from 'react'
import { Card } from 'antd'
import { connect } from 'react-redux'
import Capture from './LayoutComponents/Capture'
import Download from './LayoutComponents/Download'
import Background from './LayoutComponents/Background'
// import Showcase from './LayoutComponents/Showcase'
// import Sticker from './LayoutComponents/Sticker'
import './booth.less'

const steps = [
  <Capture />,
  // <Showcase />,
  <Background />,
  // <Sticker />,
  <Download />,
]

const mapStateToProps = ({ aiphotobooth }) => ({ aiphotobooth })
@connect(mapStateToProps)
class Booth extends React.Component {
  render() {
    const {
      aiphotobooth: { currentStep },
    } = this.props
    return (
      <div className="aiphotobooth text-center">
        <Card bordered={false} className="boothDesign">{steps[currentStep]}</Card>
      </div>
    )
  }
}

export default Booth
