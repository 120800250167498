import API from '../config/api'

export async function initiateOrder(data) {
  return API.post('generateHash',{hashstring : data.finalToken})
}

export async function verifyHash(data) {
  console.log("Verify Hash")
  console.log({hashstring : data.transactionResponse})
  return API.post('verifyHash',{hashstring : data.transactionResponse,user_id: data.user_id,amount: data.amount})
}

export async function updateTransaction(data) {
  console.log("Update Transaction")
  console.log({hashstring : data.transactionResponse})
  return API.post('updateTransaction',{hashstring : data.transactionResponse,user_id: data.user_id})
}

export async function storeCreds(data) {
  // console.log('insideStoreCreds')
  // console.log(data)
  return API.post('validateSignature', data)
}

export async function initiatePayment() {
  return ''
}
