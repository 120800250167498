import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown } from 'antd'
import Avatar from '../../../../../CleanUIComponents/Avatar'
import styles from './style.module.scss'

@connect(({ virtualUser }) => ({ virtualUser }))
class ProfileMenu extends React.Component {
  logout = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtaluser/LOGOUT',
    })
  }

  editProfile = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'virtaluser/SET_STATE',
      payload: {
        isEditProfileOpen: true,
      },
    })
  }

  openConfigurator = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'stallconfigurator/SET_STATE',
      payload: {
        isStallDesignerOpen: true,
      },
    })
  }

  render() {
    const { virtualUser } = this.props
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>{virtualUser.name || 'Anonymous'}</strong>
          <br />
          {virtualUser.email}
        </Menu.Item>
        {/* <Menu.Divider />
        <Menu.Item onClick={this.editProfile}>
          Edit Profile
        </Menu.Item> */}
        <Menu.Divider />
        {virtualUser.roles.includes('ROLE_STALL_CONFIGURATOR') ?
          <Menu.Item onClick={this.openConfigurator}>
            Configure Stall
          </Menu.Item>
            
          :
          ""
        }
        <Menu.Divider />
        <Menu.Item onClick={this.logout}>
          <i className={`${styles.menuIcon} icmn-exit`} />
          Logout
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown
        overlay={menu}
        trigger={['click']}
        className="pull-right"
        style={{ position: 'absolute', top: 0 }}
      >
        <div className={styles.dropdown}>
          <Avatar
            src={
              virtualUser.avatarType === 'text'
                ? `https://via.placeholder.com/100x100.png/${virtualUser.avatarColor.substring(
                    1,
                  )}/fff?text=${virtualUser.avatarData}`
                : virtualUser.avatarData
            }
            border
            borderColor="white"
            size="10"
          />
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
