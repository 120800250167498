/* eslint-disable */

const ObjectsToCsv = require('objects-to-csv');

export async function exportToCsv(filename, rows) {

    const csv = new ObjectsToCsv(rows);
    const csvFile=await csv.toString()
    // console.log('csv',await csv.toString());

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}