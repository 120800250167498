import React from 'react'
import { Row, Col, Card, Avatar } from 'antd'

import './leaderboardtablecard.less'

/* eslint-disable react/jsx-indent, camelcase,no-nested-ternary */

class LeaderBoardTableCard extends React.Component {
  render() {
    const {
      restRankerDetails: { points, name, my_rank, userItSelf },
    } = this.props
    return (
      <div>
        <Row id="modalContent" className="modalContent">
          <Col span={24}>
            <Card className="rank_card">
              <Row>
                <Col xs={24} sm={24} md={2}>
                  <span className="rankcard_desc_container">
                    <h2>{`#${my_rank}`}</h2>
                  </span>
                </Col>
                <Col xs={24} sm={24} md={3}>
                  <span className="rankcard_desc_container">
                    <Avatar size={48}>{name.charAt(0).toUpperCase()}</Avatar>
                  </span>
                </Col>
                <Col xs={24} sm={24} md={9}>
                  <span className="rankcard_desc_container" id="user_dec_card">
                    <h3>{!userItSelf ? name : 'My Score'}</h3>
                    {!userItSelf ? (
                      <p>Wizzy</p>
                    ) : my_rank <= 3 && my_rank > 0 ? (
                      'Congratulations! Your are in Top 3'
                    ) : (
                      ''
                    )}
                  </span>
                </Col>
                <Col xs={24} sm={24} md={10} id="rankcard_last_col">
                  <span className="rankcard_desc_container" id="pointsColm">
                    <div className="pointsContainer">{points > 0 ? `${points} Points` : 'NA'}</div>
                  </span>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default LeaderBoardTableCard
