import React from 'react'
import { Modal, Row, Col /* Skeleton */ } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleTwoTone } from '@ant-design/icons'
import Scrollbars from 'react-custom-scrollbars'
import eventActions from 'redux/virtual/event/actions'
import actions from 'redux/virtual/eshop/actions'
import ItemsLevel from './ItemLevel'
import './eshop.less'
/* eslint-disable react/jsx-indent, */
const mapStateToProps = ({ virtualEvent }) => ({
  virtualEvent,
})

@connect(mapStateToProps)
class EShop extends React.Component {
  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: eventActions.SET_STATE,
      payload: {
        isEShopOpen: false,
      },
    })
    dispatch({
      type: actions.SET_STATE,
      payload: {
        giftsData: {},
        loading: false,
      },
    })
  }

  render() {
    const {
      virtualEvent: { isEShopOpen, leaderBoardData },
    } = this.props
    const score = leaderBoardData.userScore !== undefined ? leaderBoardData.userScore.points : 0
    return (
      <div>
        <Modal
          visible={isEShopOpen}
          style={{
            top: 0,
            // display: 'flex',
            maxHeight: '100vh',
            overflow: 'hidden',
          }}
          wrapClassName="eshopModel"
          maskClosable={false}
          mask
          width="60vw"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          closeIcon={<CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '25px' }} />}
          // destroyOnClose
        >
          <ul className="circles">
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul>
          <Row gutter={24} id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 17rem)">
              <Col span={24}>
                <Row gutter={24}>
                  <Col span={24}>
                    <div className="eshop-points-row">
                      <span className="eshop-points-container">
                        <img src="https://i.ibb.co/2tvnS0R/coin-emoji.png" alt="coin" />
                        <p>{score > 0 ? `${score} Coins` : 'NA'}</p>
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <div className="store-header">
                      <h1>STORE</h1>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={24}>
                    <ItemsLevel score={score > 0 ? score : 0} />
                  </Col>
                </Row>
              </Col>
            </Scrollbars>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default EShop
