import { all, call, takeEvery, put,select } from 'redux-saga/effects'
import { fetchEnteries,entryUpload,removeLikeEntry, likeEntry } from 'services/virtual/contest'
import { message } from 'antd'
import actions from './actions'

export function* FETCH_CONTEST_ENTERIES() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
      contest: {currentContestID}
    } = state
    const contest = yield call(fetchEnteries, {token: accessToken, widgetid: currentContestID})

    if (contest.status === 200) {

      const theImageSet = []
      Object.entries(contest.data).map(key => {
        theImageSet.push({
          original: key[1].file_url,
          thumbnail: key[1].file_url,
          originalIndex: key[0]
        })
        return ''
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          contestEnteries: contest.data,
          theImageSet
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* ENTRY_UPLOAD(data) {
  try {
    const state = yield select()
    const {
      contest: { currentContestID },
      virtualUser: {accessToken}
    } = state
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        data.payload.onProgress({ percent: percentCompleted })
      },
      
    }
    
    const result = yield call(entryUpload, { file: data.payload.file, config, currentContestID, token: accessToken})
    console.log("Entry Submitted")
    console.log(result)
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          stallLogo: result.data.fileUrl,
        },
      })
      data.payload.onSuccess('ok')
      message.success('Congratulations! Your entry has been succesfully submitted.')
    } else {
      data.payload.onError({ event: result.statusText })
      message.error(
        'Oops! There seems to be an issue with submitting your entry. Check your internet and try again',
      )
    }
  } catch (err) {
    data.payload.onError({ event: err.response.data.message })
    console.log(err.response)
    message.error(err.response.data.message)
    message.error(
      'Oops! There seems to be an issue with submitting your entry. Check your internet and try again',
    )
  }
}

export function* REMOVE_LIKE(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    yield call(removeLikeEntry, {token: accessToken, entryID: data.payload.entryID})
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* ADD_LIKE(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state
    yield call(likeEntry, {token: accessToken, entryID: data.payload.entryID})
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_CONTEST_ENTERIES, FETCH_CONTEST_ENTERIES),
    takeEvery(actions.ENTRY_UPLOAD, ENTRY_UPLOAD),
    takeEvery(actions.ADD_LIKE, ADD_LIKE),
    takeEvery(actions.REMOVE_LIKE, REMOVE_LIKE),
  ])
}
