import React from 'react'
import { Modal, Row, Col, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { CloseCircleOutlined } from '@ant-design/icons'
import ReactPlayer from 'react-player'
// import { Scrollbars } from 'react-custom-scrollbars'
import actions from 'redux/virtual/event/actions'

import './tutvideo.less'

const mapStateToProps = ({ virtualEvent }) => ({ virtualEvent })

@connect(mapStateToProps)
class VideoDemand extends React.Component {

  handleClose = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        onDemandVideoOpen: false
      },
    })
  }

  render() {
    const {
      virtualEvent: { onDemandVideoOpen,onDemandVideoURL },
    } = this.props
    return (
      <div>
        <Modal
          visible={onDemandVideoOpen}
          style={{
            // top: 0,
            // display: 'flex',
            maxHeight: '100vh',
          }}
          wrapClassName="tutvideoModal"
          maskClosable={false}
          mask
          width="60rem"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          maskStyle={{backgroundColor: "rgba(15, 12, 41, 0.93)"}}
          closeIcon={<Tooltip title="Close Video"><CloseCircleOutlined style={{ color: 'white', fontSize: '25px' }} /></Tooltip>}
          destroyOnClose
        >
          <Row gutter={24} id="modalContent" className="modalContent">
            {/* <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 250px)"> */}
            <Col span={24}>
              <ReactPlayer
                url={onDemandVideoURL}
                playing
                controls
                width="60rem"
                height="34rem"
                // loop
                onEnded={this.handleClose}
                volume={1}
                style={{ backgroundColor: '0 0 0 0.5' }}
              />
            </Col>
            {/* </Scrollbars> */}
          </Row>
        </Modal>
      </div>
    )
  }
}

export default VideoDemand
