import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import Loader from 'components/LayoutComponents/Loader'
import PublicLayout from './Public'
import LoginLayout from './Login'
import MainLayout from './Main'
import VirtualEvent from './Virtual/Main'
import VirtualLogin from './Virtual/Login'
import CacheBuster from '../CacheBuster';

const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
  event: VirtualEvent,
  vlogin: VirtualLogin,
}
/* eslint-disable no-else-return */
@withRouter
@connect(({ user, virtualUser }) => ({ user, virtualUser })) // Getting user's initialState and mapping it to props
class IndexLayout extends React.PureComponent {
  previousPath = ''

  constructor(props) {
    super(props)
    const {
      location: { pathname },
      virtualUser,
      dispatch,
    } = props
    try{
      if (/^\/virtual(?=\/|$)/i.test(pathname)) {
        if (localStorage.getItem('acctoken') !== null && !virtualUser.authorized) {
          const theUser = localStorage.getItem('userData')
          dispatch({
            type: 'virtualuser/LOGIN_HELPER',
            payload: {
              accessToken: localStorage.getItem('acctoken'),
              id: localStorage.getItem('userid'),
              tokenType: localStorage.getItem('tokenType'),
              userData: JSON.parse(theUser),
            },
          })
        }
      }
    }
    catch(e){
      console.log(e)
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { prevLocation } = prevProps
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user,
      virtualUser,
    } = this.props

    // NProgress Management
    const currentPath = pathname + search
    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/' || pathname === '/main') {
        return { layout: 'public', section: 'landing' }
      }
      if (/^\/virtual\/auth(?=\/|$)/i.test(pathname)) {
        return { layout: 'vlogin', section: 'virtual' }
      }
      if (/^\/virtual(?=\/|$)/i.test(pathname)) {
        return { layout: 'event', section: 'virtual' }
      }
      if (/^\/user(?=\/|$)/i.test(pathname)) {
        return { layout: 'login', section: 'cms' }
      }
      return { layout: 'main', section: 'cms' }
    }

    const selectedLayout = getLayout()
    const Container = Layouts[selectedLayout.layout]
    let isvirtualUserAuthorized = ''
    let isvirtualUserLoading = ''
    let isLoginVirtualLayout = ''
    let isUserAuthorized = ''
    let isUserLoading = ''
    let isLoginLayout = ''

    console.log('Debugging Big time')
    console.log(`Selected Alyout ${selectedLayout.section}`)
    console.log(`Access Token : ${localStorage.getItem('acctoken')}`)
    console.log(`Autorized? : ${virtualUser.authorized}`)
    if (selectedLayout.section === 'virtual') {
      isvirtualUserAuthorized = virtualUser.authorized
      isvirtualUserLoading = virtualUser.loading
      isLoginVirtualLayout = selectedLayout.layout === 'vlogin'
    } else if (selectedLayout.section === 'cms') {
      isUserAuthorized = user.authorized
      isUserLoading = user.loading
      isLoginLayout = selectedLayout.layout === 'login'
    }

    const BootstrappedLayout = () => {
      if (selectedLayout.section === 'virtual') {
        // show loader when user in check authorization process, not authorized yet and not on login pages
        if (isvirtualUserLoading && !isvirtualUserAuthorized && !isLoginVirtualLayout) {
          return <Loader />
        }
        // redirect to login page if current is not login page and user not authorized
        if (!isLoginVirtualLayout && !isvirtualUserAuthorized) {
          return <Redirect to="/virtual/auth/login" />
        }
        // redirect to main dashboard when user on login page and authorized
        if (isLoginVirtualLayout && isvirtualUserAuthorized) {
          return <Redirect to="/virtual/main" />
        }
        // in other case render previously set layout
        return <Container>{children}</Container>
      } else if (selectedLayout.section === 'cms') {
        // show loader when user in check authorization process, not authorized yet and not on login pages
        if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
          return <Loader />
        }
        // redirect to login page if current is not login page and user not authorized
        if (!isLoginLayout && !isUserAuthorized) {
          return <Redirect to="/user/login" />
        }
        // redirect to main dashboard when user on login page and authorized
        if (isLoginLayout && isUserAuthorized) {
          return <Redirect to="/dashboard/alpha" />
        }
        // in other case render previously set layout
        return <Container>{children}</Container>
      } else {
        return <Container>{children}</Container>
      }
    }
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }

          
          return (
            <Fragment>
              {selectedLayout.section === 'virtual' ? (
                // <Helmet titleTemplate="Wiz365 | %s" title="Wiz365 | Virtual Event Demo" />
                <Helmet titleTemplate={`${process.env.REACT_APP_EVENT_NAME} | %s`} title={`${process.env.REACT_APP_EVENT_NAME} | Virtual Event`} />
              ) : (
                <Helmet titleTemplate={`${process.env.REACT_APP_EVENT_NAME} | %s`} title={` | ${process.env.REACT_APP_EVENT_NAME}`} />
              )}
              {BootstrappedLayout()}
            </Fragment>
          )
        }}
      </CacheBuster>
    )
  }
}

export default IndexLayout
