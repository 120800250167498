import React from 'react'
import { Row, Col, Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import actions from 'redux/virtual/photobooth/actions'
import Base64Downloader from 'react-base64-downloader'

const mapStateToProps = ({ photobooth }) => ({ photobooth })

@connect(mapStateToProps)
class Download extends React.Component {
  goBack = () => {
    const {
      dispatch,
      photobooth: { currentStep },
    } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: currentStep - 1,
        toonifiedImage: '',
        imageProcessing: false,
      },
    })
  }

  resetPhotobooth = () => {
    const { dispatch } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        currentStep: 0,
        toonifiedImage: '',
        imageSrc: '',
        webcamLoading: false,
        imageProcessing: false,
      },
    })
  }
  // downloadFile = () => {
  //   const {photobooth: {toonifiedImage}} = this.props

  // }

  render() {
    const {
      photobooth: { imageSrc },
    } = this.props
    return (
      <>
        <Row justify="space-around" align="middle" className="fullHeightScreen">
          <Col span={16} className="text-left">
            <img
              src={imageSrc}
              alt="newImage"
              style={{ width: '100%', borderRadius: '20px 20px 20px 20px' }}
            />
          </Col>
          <Col span={8} className="text-center downloadArea" style={{ padding: '10px' }}>
            <h3 className="white-text">Looking Great !!!</h3>
            <h5 className="white-text">Download your photo</h5>
            <br />
            <Base64Downloader
              base64={imageSrc}
              downloadName="ClassicPhotobooth"
              stye={{ backgroundColor: '#007db600', border: 0 }}
            >
              <Button size="large" className="actualDownloadButton">
                <DownloadOutlined />
              </Button>
            </Base64Downloader>
          </Col>
        </Row>
      </>
    )
  }
}

export default Download
