export async function getLeftMenuData(isVirtualMobileView, navigationData, isExhibitor) {
  let isLogout = ''
  if (isVirtualMobileView) {
    isLogout = {
      title: 'Logout',
      key: 'logout',
      icon: 'icmn icmn-switch',
    }
  }
  let thefinalLeg = {}
  if (isExhibitor) {
    thefinalLeg = {
      title: 'Leads',
      key: 'leads',
      icon: 'icmn icmn-user-check',
    }
  }

  // let theDirectory = {}
  // if (!isExhibitor) {
  //   theDirectory = {
  //     title: 'Directory',
  //     key: 'directory',
  //     icon: 'icmn icmn-book',
  //   }
  // }

  console.log('is logout?')
  console.log(isLogout)

  return [
    {
      title: 'Back',
      key: 'back',
      icon: 'icmn icmn-undo2',
    },
    {
      title: 'Home',
      key: 'home',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Agenda',
      key: 'agenda',
      icon: 'icmn icmn-calendar',
    },
    {
      title: 'Chat',
      key: 'sidebar',
      icon: 'icmn icmn-bubbles2',
    },
    {
      title: 'Navigation',
      key: 'navigation',
      icon: 'icmn icmn-compass2',
      children: navigationData,
    },

    {
      title: 'Briefcase',
      key: 'briefcase',
      icon: 'icmn icmn-briefcase',
    },

    // {
    //   title: 'Helpdesk',
    //   key: 'helpdesk',
    //   icon: 'icmn icmn-info',
    // },
    // {
    //   title: 'My Points',
    //   key: 'pointredeem',
    //   icon: 'icmn icmn-coin-dollar',
    // },
    {
      title: 'Speakers',
      key: 'speakers',
      icon: 'icmn icmn-man',
    },
    {
      title: 'Meetings',
      key: 'meeting',
      icon: 'icmn icmn-user-tie',
    },
    {
      title: 'Leader Board',
      key: 'leaderBoard',
      icon: 'icmn icmn-users',
    },
    {
      title: 'E-Shop',
      key: 'eShop',
      icon: 'icmn icmn-cart',
    },
    // theDirectory,
    isLogout,
    thefinalLeg,
  ]
}
export async function getTopMenuData(isVirtualMobileView, navigationData, isExhibitor) {
  console.log('in menu top')
  console.log(isVirtualMobileView)
  let isLogout = ''
  if (isVirtualMobileView) {
    isLogout = {
      title: 'Logout',
      key: 'logout',
      icon: 'icmn icmn-switch',
    }
  }
  let thefinalLeg = {}
  if (isExhibitor) {
    thefinalLeg = {
      title: 'Leads',
      key: 'leads',
      icon: 'icmn icmn-user-check',
    }
  }

  // let theDirectory = {}
  // if (!isExhibitor) {
  //   theDirectory = {
  //     title: 'Directory',
  //     key: 'directory',
  //     icon: 'icmn icmn-book',
  //   }
  // }
  return [
    {
      title: 'Back',
      key: 'back',
      icon: 'icmn icmn-undo2',
    },
    {
      title: 'Home',
      key: 'home',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Agenda',
      key: 'agenda',
      icon: 'icmn icmn-calendar',
    },
    {
      title: 'Chat',
      key: 'sidebar',
      icon: 'icmn icmn-bubbles2',
    },
    {
      title: 'Navigation',
      key: 'navigation',
      icon: 'icmn icmn-compass2',
      children: navigationData,
    },

    {
      title: 'Briefcase',
      key: 'briefcase',
      icon: 'icmn icmn-briefcase',
    },

    // {
    //   title: 'Helpdesk',
    //   key: 'helpdesk',
    //   icon: 'icmn icmn-info',
    // },
    // {
    //   title: 'My Points',
    //   key: 'pointredeem',
    //   icon: 'icmn icmn-coin-dollar',
    // },
    // {
    {
      title: 'Speakers',
      key: 'speakers',
      icon: 'icmn icmn-man',
    },
    {
      title: 'Meetings',
      key: 'meeting',
      icon: 'icmn icmn-user-tie',
    },
    {
      title: 'Leader Board',
      key: 'leaderBoard',
      icon: 'icmn icmn-users',
    },
    {
      title: 'E-Shop',
      key: 'eShop',
      icon: 'icmn icmn-cart',
    },
    // Object.keys(theDirectory).length !== 0 ? theDirectory : '',
    thefinalLeg,
    isLogout,
  ]
}
