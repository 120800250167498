import { 
  all, 
  call, 
  takeEvery, 
  select,
  // put 
} from 'redux-saga/effects'
// import { getAgenda, getAllSpeakers } from 'services/virtual/setup'
import { pubnubPublish } from 'services/virtual/chat'
import { message } from 'antd'
import floating from 'components/LayoutComponents/Virtual/Reactions/floating'
// import { store as reduxStore } from 'index'
import actions from './actions'

/* eslint-disable no-shadow */

export function* FLOAT_REACTION(data) {
  try {
    const state = yield select()
    const { 
      reactions: {emojis},
    } = state
    const floatDuration = 4
    floating({
      content: `<img src=${emojis[data.payload.reactionType]} alt=${data.payload.reactionType} />`,
      number: 3,
      duration: floatDuration,
      repeat: 1,
    });


    // yield put({
    //   type: actions.SET_STATE,
    //   payload: {
    //     totalEmojiFlying: totalEmojiFlying + 1
    //   }
    // })

    // setTimeout(function* () {
    //   const state2 = yield select()
    //   const { 
    //     reactions: {totalEmojiFlying},
    //   } = state2
    //   reduxStore.dispatch({
    //     type: actions.SET_STATE,
    //     payload: {
    //       totalEmojiFlying: totalEmojiFlying - 1
    //     }
    //   })
    // },(floatDuration+1)*1000)


  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SEND_REACTION(data) {
  try {
    const state = yield select()
    const { 
      Chat: { pubnub },
      virtualEvent: {currentSession},
      virtualUser: {id}
    } = state

    // const temprecentEmojis = recentEmojis
    // temprecentEmojis[data.payload.reactionType] = 1
    // yield put({
    //   type: actions.SET_STATE,
    //   payload: {
    //     lastEmoji: data.payload.reactionType,
    //     recentEmojis: temprecentEmojis
    //   }
    // })
    const finalMessage = {
      reactionType: data.payload.reactionType,
      publisher: id,
      type: 'reactions',
    }

    const publishData = {
      channelID: `${currentSession}-reactions`,
      message: finalMessage,
      pubnub,
    }
    yield call(pubnubPublish, publishData)

  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.FLOAT_REACTION, FLOAT_REACTION),
    takeEvery(actions.SEND_REACTION, SEND_REACTION)
  ])
}
